import React, { useState, useEffect } from "react";

const Input = ({
  container,
  label,
  id,
  name,
  handleChange,
  order,
  text = "",
  placeholder = "",
  mt = false,
  type = "text",
  bigInput = false,
  disabled = false,
  handleLabelChange,
  editableLabel = false
}) => {
  const [editing, setEditing] = useState(false);
  const [labelText, setLabelText] = useState(label);

  useEffect(() => {
    setLabelText(label);
  }, [label]);

  const handleDoubleClick = () => {
    if (editableLabel) setEditing(true);
  };


  return (
    <div className={`block ${container}`}>
      {editing ? (
        <input
          className="border p-1 rounded"
          value={labelText}
          onChange={(e) => setLabelText(e.target.value)}
          onBlur={() => {
            handleLabelChange(id, labelText);
            setEditing(false);
          }}
          autoFocus
        />
      ) : (
        <label
          className={`block font-semibold ${order} cursor-pointer`}
          htmlFor={id}
          onDoubleClick={handleDoubleClick}
        >
          {labelText}
        </label>
      )}

      {bigInput ? (
        <textarea
          rows={6}
          className={`block w-full outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded ${
            mt ? mt : "mt-4"
          } min-w-[300px]`}
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={(e) => handleChange(name, e.target.value)}
          value={text}
        />
      ) : (
        <input
          className={`block ${
            type === "checkbox" ? "" : `w-full ${mt ? mt : "mt-4"}`
          } outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded min-w-[300px]`}
          type={type}
          id={id}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          value={text}
          onChange={(e) => handleChange(name, e.target.value)}
        />
      )}
    </div>
  );
};

export default Input;
