import React, { useEffect, useState } from "react";
import api from "../../api/axios";

const GammeProd = () => {
    const [chains, setChains] = useState([]);
    const [selectedChain, setSelectedChain] = useState("");
    const [model, setModel] = useState(null);
    const [selectedModel, setSelectedModel] = useState("");
    const [totalChains, setTotalChains] = useState(0);
    const [error, setError] = useState(null);
    const [couteTransport, setCouteTransport] = useState(0);
    const [effectifInDirect, setEffectifInDirect] = useState(0);
    const [systemConstant, setSystemConstant] = useState({
        Nombre_d_heures_par_jour: "",
        Taux_horaire_SMIG_16_29: "",
        Taux_horaire_17_00: "",
        Taux_horaire_17_50: "",
        Masse_salariale_16_29: "",
        Masse_salariale_17_00: "",
        Masse_salariale_17_50: "",
        Capacité_par_unité_transport: "",
        cotisation_entroprise_trans: "",
        Coût_par_trajet: "",
        Coût_énergie_journalier: "",
        Coût_charges_fixes_journalier: "",
    });
    const [totalHours, setTotalHours] = useState(0);
    const [effectifDirect, setEffectifDirect] = useState(0);
    const [models, setModels] = useState([]);
    const [totalMassSalary, setTotalMassSalary] = useState(0);
    const [fils, setFils] = useState(0);
    const [plastique, setPlastique] = useState(0);
    const [coûtEnergie, setCoûtEnergie] = useState(0);
    const [coûtChargesFixes, setCoûtChargesFixes] = useState(0);
    const [coûtDeRevient, setCoûtDeRevient] = useState(0);
    const [totalExport, setTotalExport] = useState(0);
    const [temp, setTemp] = useState(0);
    const [coutMin, setCoutmin] = useState(0);
    const [totalTemp, setTotalTemp] = useState(0);
    const [rendemen, setRendement] = useState(null);


    // API call functions
    const fetchModelByChain = async (selectedChain) => {
          try {
              const response = await api.get(`/getmodelsByChain/${selectedChain}`);
              setSelectedModel(response.data.id);
              setModel(response.data);
              getTempAlloue(response.data.id);
              getExport(response.data.id);
          } catch (error) {
              console.error("Error fetching models by chain:", error);
          }
    };
  
    const getTempAlloue = async (selectedModel) => {
      if (selectedModel) {
          try {
              const response = await api.get(`/temps-alloue/${selectedModel}`);
              const tempAlloue = response.data.temps_alloue;
              setTotalTemp(tempAlloue.temps_alloue);
          } catch (error) {
              console.error("Error fetching temps_alloue:", error);
          }
      }
    };

    const getExport = async (selectedModel) => {
        if (selectedModel) {
            try {
                const response = await api.get(`/models/${selectedModel}`);
                if (response.data && response.data.qte_societe) {
                    setTotalExport(response.data.qte_societe);
                }
            } catch (error) {
                console.error("Error fetching export data:", error);
            }
        }
    };

    const fetchChains = async () => {
        try {
            const response = await api.get("/chains");
            setChains(response.data);
        } catch (error) {
            console.error("Error fetching chains:", error);
        }
    };

    const fetchSystemConstants = async () => {
        try {
            const systemResponse = await api.get("/system_constants_latest");
            if (systemResponse.data) {
                const { id, created_at, updated_at, effectif_fix, ...filteredData } = systemResponse.data;
                setSystemConstant(filteredData);
            }
        } catch (error) {
            console.error("Error fetching system constants:", error);
        }
    };

    const fetchEffectifDirectIndirect = async () => {
        try {
            const responseIndirect = await api.get(`/effective_indirect_total`);
            setEffectifInDirect(parseInt(responseIndirect.data.total_effectif_indirect));
    
            if (selectedModel) {
                const responseDirect = await api.get(`/effective_direct_standard/${selectedModel}`);
                setEffectifDirect(parseInt(responseDirect.data.total_effectif_direct));
            }
    
            const responseChains = await api.get(`/total_chain`);
            setTotalChains(parseInt(responseChains.data.total_chains));
        } catch (error) {
            console.error("Error fetching direct/indirect effectif data:", error);
        }
    };

    const fetchWorkedHours = async (modelId) => {
      try {
        const response = await api.get(`/product_plans_worked_hours/${modelId}`);
        setTotalHours(response.data.worked_hours_count);
      } catch (error) {
        console.error("Error fetching worked hours:", error);
        setError(error.response?.data?.message || "An error occurred");
      }
    };

    const fetchModels = async () => {
      try {
        const modelsResponse = await api.get("/models");
        setModels(modelsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };


    
    // Calculation functions
    const calculateCouteTransport = () => {
        const QP_effectif = effectifInDirect / totalChains;
        const { Nombre_d_heures_par_jour, Capacité_par_unité_transport, Coût_par_trajet, cotisation_entroprise_trans } = systemConstant;
        const Nombre_de_jours_de_production = totalHours / Nombre_d_heures_par_jour;
        const Refacturation_transport_MOD_indirecte = -((cotisation_entroprise_trans * Nombre_de_jours_de_production * QP_effectif) / 26);
        const Refacturation_transport_MOD_directe = -((cotisation_entroprise_trans * Nombre_de_jours_de_production * effectifDirect) / 26);
        const Nombre_unite_utilise = (QP_effectif + effectifDirect) / Capacité_par_unité_transport;
        const transportCost = 2 * Nombre_unite_utilise * Nombre_de_jours_de_production * Coût_par_trajet + (Refacturation_transport_MOD_directe + Refacturation_transport_MOD_indirecte);
        setCouteTransport(transportCost.toFixed(4));
    };

    const calculateTotalMasseSalariale = () => {
        const { Taux_horaire_SMIG_16_29, Taux_horaire_17_00, Taux_horaire_17_50, Masse_salariale_16_29, Masse_salariale_17_00, Masse_salariale_17_50 } = systemConstant;
        const congeAnual = (totalHours * effectifDirect * Taux_horaire_SMIG_16_29 * 1.5) / 26;
        const masseSalariale1629 = totalHours * effectifDirect * Masse_salariale_16_29 * 0.01 * Taux_horaire_SMIG_16_29;
        const masseSalariale1700 = totalHours * effectifDirect * Masse_salariale_17_00 * 0.01 * Taux_horaire_17_00;
        const masseSalariale1750 = totalHours * effectifDirect * Masse_salariale_17_50 * 0.01 * Taux_horaire_17_50;
        const masseSalarialeDirecte = masseSalariale1629 + masseSalariale1700 + masseSalariale1750 + congeAnual;
      
        const QP_effectif = effectifInDirect / totalChains;
        const congeAnualIndirect = (totalHours * QP_effectif * Taux_horaire_SMIG_16_29 * 1.5) / 26;
        const masseSalarialeIndirect1629 = totalHours * QP_effectif * Masse_salariale_16_29 * 0.01 * Taux_horaire_SMIG_16_29;
        const masseSalarialeIndirect1700 = totalHours * QP_effectif * Masse_salariale_17_00 * 0.01 * Taux_horaire_17_00;
        const masseSalarialeIndirect1750 = totalHours * QP_effectif * Masse_salariale_17_50 * 0.01 * Taux_horaire_17_50;
        const masseSalarialeInDirecte = masseSalarialeIndirect1629 + masseSalarialeIndirect1700 + masseSalarialeIndirect1750 + congeAnualIndirect;
      
        setTotalMassSalary(masseSalarialeInDirecte + masseSalarialeDirecte);
    };
    
    const calculateCouteFilAndPlastique = () => {
        setFils(totalExport * (20000 / 18602));
        setPlastique(totalExport * (15000 / 18602));
    };
    
    const calculateCoûtEnergie = () => {
        const { Coût_énergie_journalier, Nombre_d_heures_par_jour } = systemConstant;
        const Nombre_de_jours_de_production = totalHours / Nombre_d_heures_par_jour;
        setCoûtEnergie(Nombre_de_jours_de_production * Coût_énergie_journalier);
    };
  
    const calculateCoûtChargesFixes = () => {
      const { Coût_charges_fixes_journalier, Nombre_d_heures_par_jour } = systemConstant;
      const Nombre_de_jours_de_production = totalHours / Nombre_d_heures_par_jour;
      setCoûtChargesFixes(Nombre_de_jours_de_production * Coût_charges_fixes_journalier);
    };

    const calculateCoûtDeRevient = () => {
      const cout = Number(couteTransport) + Number(coûtEnergie) + Number(coûtChargesFixes) + Number(totalMassSalary) + Number(totalMassSalary * 0.2) + Number(fils) + Number(plastique);
      setCoûtDeRevient(cout.toFixed(4));
    };

    const calculateCoutmin = () => {
      const cout = Number(coûtDeRevient) / temp;
      setCoutmin(cout.toFixed(4));
    };
    
    // helper function
    const handleSelectChange = (e) => {
        setSelectedChain(e.target.value);
        setModel(null); // Reset model on chain change
    };

    const getChainName = (chainId) => {
        const chain = chains.find((chain) => chain.id == chainId);
        return chain ? chain.name : "";
    };

    useEffect(() => {
      if (selectedModel) {
          calculateCouteTransport();
          calculateTotalMasseSalariale();
          calculateCouteFilAndPlastique();
          calculateCoûtEnergie();
          calculateCoûtChargesFixes();
      }
  }, [effectifDirect, totalChains, selectedModel, systemConstant, totalHours]);

    useEffect(() => {
        calculateCoûtDeRevient();
        calculateCoutmin();
    }, [couteTransport , coûtEnergie , coûtChargesFixes , totalMassSalary , fils , plastique])

    useEffect(() => {
        fetchChains();
        fetchSystemConstants();
    }, []);
    
    useEffect(() => {
      fetchModels();
    }, []);

    useEffect(() => {
      if (selectedChain) {
          fetchModelByChain(selectedChain);
      }
    }, [selectedChain]);

    useEffect(() => {
        fetchEffectifDirectIndirect();
    }, [selectedModel]);

    useEffect(() => {
      if (selectedModel) {
        fetchWorkedHours(selectedModel);
      }
    }, [selectedModel]);

    useEffect(() => {
      const min_presence = totalHours * effectifDirect * 60;
      const min_produit = totalExport * totalTemp; 
      if (min_presence > 0) {
        const Rendement = min_produit / min_presence;
        setRendement(Rendement)
        setTemp(effectifDirect * totalHours * 60 * Rendement);
      }
    }, [effectifDirect, totalHours, totalTemp]);

    function formatNumber(value) {
        // Ensure the number is rounded to 1 decimal place
        let rounded = Number(value).toFixed(2);
    
        // Format using Intl.NumberFormat
        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(rounded);
    }
    

    return (
        <div className="ml-7 mt-5">
        <div className="flex items-center justify-between">
            <h2 className="text-2xl font-semibold">La Gamme de production</h2>
            <div className="ml-7 mb-4 pr-6">
            <select
                className="block w-full mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
                value={selectedChain}
                onChange={handleSelectChange}
            >
                <option value="">Select a Chain</option>
                {chains.map((chain) => (
                <option key={chain.id} value={chain.id}>
                    {chain.name}
                </option>
                ))}
            </select>
            </div>
        </div>

        {model && (
            <div className="overflow-x-auto mt-5">
            <table className="table-auto w-full bg-slate-200 border-collapse border border-gray-300 shadow-md">
                <thead className="bg-gray-100">
                <tr>
                    <th className="px-6 w-[30%] py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">
                    Field
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">
                    Value
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">Chaine</td>
                    <td className="p-[2rem] text-left text-[#4E4A4A] border border-gray-300">{getChainName(selectedChain)}</td>
                </tr>
                <tr>
                    <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">Modèle</td>
                    <td className="p-[2rem] text-left text-[#4E4A4A] border border-gray-300">{model.modele}</td>
                </tr>
                <tr>
                    <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">Client</td>
                    <td className="p-[2rem] text-left text-[#4E4A4A] border border-gray-300">{model.client}</td>
                </tr>
                <tr>
                    <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">Photo</td>
                    <td className="p-[2rem] text-left text-[#4E4A4A] border border-gray-300">
                    <img
                        src={`http://crosscheckconf.net:8000${model.image}`}
                        alt={model.name}
                        className="h-[50px] w-[50px]"
                    />
                    </td>
                </tr>
                <tr>
                    <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">Nombre de pièces du modèle</td>
                    <td className="p-[2rem] text-left text-[#4E4A4A] border border-gray-300">{formatNumber(model.qte_societe)}</td>
                </tr>
                <tr>
                    <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">Prix main d'euvre de l'article</td>
                    <td className="p-[2rem] text-left text-[#4E4A4A] border border-gray-300">{formatNumber(model.prixMOver)} {model.devise}</td>
                </tr>
                <tr>
                    <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">Coût minute prévisionnel</td>
                    <td className="p-[2rem] text-left text-[#4E4A4A] border border-gray-300">{formatNumber((Number(coutMin) || 0)) }</td>
                </tr>
                <tr>
                    <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">Taux de rendement prévisionnel</td>
                    <td className="p-[2rem] text-left text-[#4E4A4A] border border-gray-300">{rendemen && formatNumber(rendemen)} %</td>
                </tr>
                <tr>
                    <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">Coût de revient prévisionnel de l'article</td>
                    <td className="p-[2rem] text-left text-[#4E4A4A] border border-gray-300">{coutMin && totalTemp && formatNumber(((Number(coutMin) || 0) * totalTemp))}</td>
                </tr>
                <tr>
                    <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">Marge prévisionnelle de l'article</td>
                    <td className="p-[2rem] text-left text-[#4E4A4A] border border-gray-300">{formatNumber((model.qte_societe * model.prixMOver - (Number(coutMin) || 0).toFixed(2) * totalTemp))} {model.devise}</td>
                </tr>
                <tr>
                    <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">Taux de marge prévisionnelle</td>
                    <td className="p-[2rem] text-left text-[#4E4A4A] border border-gray-300">{formatNumber(((model.qte_societe * model.prixMOver - (Number(coutMin) || 0).toFixed(2)) / model.qte_societe * model.prixMOver))} %</td>
                </tr>
                </tbody>
            </table>
            </div>
        )}
        </div>
    );
};

export default GammeProd;