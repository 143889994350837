import React, { useState, useEffect } from 'react';
import api from "../../api/axios";

const Retouche = () => {
    const [selectedChain, setSelectedChain] = useState('');
    const [retouches , setRetouches] = useState([])
    const [chains, setChains] = useState([]);
    const [model , setModel] = useState(null)

    useEffect(() =>{
        const fetchChains = async () => {
            try {
              const response = await api.get("/chains");
              setChains(response.data);
            } catch (error) {
              console.error("Error fetching chains:", error);
            }
          };

          fetchChains()
    }, [])

    useEffect(() => {
        const fetchModelByChain = async () => {
            if (selectedChain) {
                try {
                    const response = await api.get(`/getmodelsByChain/${selectedChain}`);
                    setModel(response.data);
                } catch (error) {
                    console.error("Error fetching model by chain:", error);
                    setModel(null);
                }
            }
        };

        fetchModelByChain();
    }, [selectedChain])

    useEffect(() => {
        const fetchRetouchData = async () => {
            try {
              const response = await api.get(`/getRetouchData/${model && model.id}/${selectedChain}`);
              setRetouches(response.data);
            } catch (error) {
              console.error('Error fetching retouch data:', error);
            }
          };

        fetchRetouchData()
      
    }, [selectedChain , model])

  return (
    <div>
       <div className="flex items-center justify-between">
            <h2 className="ml-7 mb-9 text-2xl font-semibold">
                Suivi de retouches 
            </h2>

            <select 
                className="block ml-7 mb-4 w-fit mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
                onChange={e => setSelectedChain(e.target.value)} value={selectedChain}>
                <option value="">Select Chain (Sortie)</option>
                {chains.map(chain => (
                <option key={chain.id} value={chain.id}>
                    {chain.name}
                </option>
                ))}
            </select>
        </div >
        {model && (
                <div className='ml-7 mt-5'>
                    <p><strong>Modèle:</strong> {model.modele}</p>
                    <p><strong>Catégorie:</strong> {model.category}</p>
                    <p><strong>Photo:</strong>
                        <img
                            src={`http://crosscheckconf.net:8000${model.image}`}
                            alt={model.name}
                            className="h-[50px] w-[50px]"
                        />
                    </p>
                </div>
            )}
            {retouches.length > 0 && (
                <div className='ml-7 mt-5'>
                    <table className=' table-auto w-full bg-slate-200 border-collapse border border-gray-300 shadow-md'>
                        <thead className="bg-gray-100">
                            <tr>
                                <th className='px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300'>Date de Production</th>
                                <th className='px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300'>Jour</th>
                                <th className='px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300'>Production Réelle par Chaine</th>
                                <th className='px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300'>Retouches par Chaine</th>
                                <th className='px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300'>Production Réelle Controle Final</th>
                                <th className='px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300'>Retouches Controle Final</th>
                            </tr>
                        </thead>
                        <tbody>
                            {retouches.map((row, index) => (
                                <tr key={index}>
                                    <td className='p-[2rem] min-w-[200px] text-left text-[#4E4A4A] border font-semibold border border-gray-300'>{row.date_de_production}</td>
                                    <td className='p-[2rem] min-w-[200px] text-left text-[#4E4A4A] border font-semibold border border-gray-300'>{row.jour}</td>
                                    <td className='p-[2rem] min-w-[200px] text-left text-[#4E4A4A] border font-semibold border border-gray-300'>{row.production_reelle_par_chaine.total_sortie}</td>
                                    <td className='p-[2rem] min-w-[200px] text-left text-[#4E4A4A] border font-semibold border border-gray-300'>
                                        <ul>
                                            {row.retouches_par_chaine.map((retouch, i) => (
                                                <li key={i}>
                                                    {retouch.post.name} - {retouch.value}
                                                </li>
                                            ))}
                                        </ul>
                                    </td>
                                    <td className='p-[2rem] min-w-[200px] text-left text-[#4E4A4A] border font-semibold border border-gray-300'>{row.production_reelle_controle_final}</td>
                                    <td className='p-[2rem] min-w-[200px] text-left text-[#4E4A4A] border font-semibold border border-gray-300'>{row.retouches_controle_final}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
    </div>
  )
}

export default Retouche
